import { TabBar } from "antd-mobile";
import {
  AppOutline,
  MessageOutline,
  UnorderedListOutline,
  UserOutline,
} from "antd-mobile-icons";

export default function AppFooter() {
  const tabs = [
    {
      key: "home",
      title: "Home",
      icon: <AppOutline />,
    },
    {
      key: "todo",
      title: "Todo",
      icon: <UnorderedListOutline />,
    },
    {
      key: "message",
      title: "Message",
      icon: <MessageOutline />,
    },
    {
      key: "profile",
      title: "Profile",
      icon: <UserOutline />,
    },
  ];
  return (
    <TabBar>
      {tabs.map((item) => (
        <TabBar.Item key={item.key} icon={item.icon} title={item.title} />
      ))}
    </TabBar>
  );
}
