import "mapbox-gl/dist/mapbox-gl.css";
import { Button, Toast } from "antd-mobile";
import { getCurrentLocation } from "./getCurrentLocation";
import { useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import UIFlexbox from "../ui/UIFlexbox";

mapboxgl.accessToken =
  "pk.eyJ1IjoiZGVzbW9uZHllb2giLCJhIjoiY2t4cTFqcXBuMnBoaTJwdWJhdG42amc0cCJ9.WJ2YhwPiLhI63grXURJSDg";

export default function LocatorApp() {
  const [location, setLocation] = useState<GeolocationPosition | null>(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    getCurrentLocation(
      (position) => {
        setLocation(position);
      },
      (err) => {
        setErrorMessage(err.message);
      }
    );
  }, []);

  const latitude = location?.coords.latitude;
  const longitude = location?.coords.longitude;

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: "map", // container ID
      style: "mapbox://styles/mapbox/streets-v12", // style URL
      center: [longitude ?? 0, latitude ?? 0], // starting position [lng, lat]
      zoom: 9, // starting zoom
    });
    map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: {
          enableHighAccuracy: true,
        },
        trackUserLocation: true,
        showUserHeading: true,
      })
    );
  }, [latitude, longitude]);
  const copyLocationToClipboard = () => {
    navigator.clipboard.writeText(
      `${location?.coords.latitude},${location?.coords.longitude}`
    );
    Toast.show({
      content: (
        <p style={{ wordBreak: "normal" }}>
          Location has been copied to clipboard!
        </p>
      ),
    });
  };
  return (
    <UIFlexbox
      alignItems="center"
      direction="column"
      justifyContent="center"
      gap={4}
      grow={1}
    >
      <div id="map" style={{ height: "100%", width: "100%" }}></div>
      {errorMessage && <p>{errorMessage}</p>}
      {location != null && <p>Get location success!</p>}
      <p>Longitude: {longitude}</p>
      <p>Latitude: {latitude}</p>
      <Button size="small" onClick={copyLocationToClipboard}>
        Copy to clipboard
      </Button>
    </UIFlexbox>
  );
}
