import { Property } from "csstype";

type Props = {
  alignItems?: Property.AlignItems;
  children: React.ReactNode;
  className?: string;
  direction?: Property.FlexDirection;
  gap?: 0 | 4 | 8 | 12 | 16 | 20 | 24;
  grow?: Property.FlexGrow;
  justifyContent?: Property.JustifyContent;
  shrink?: Property.FlexShrink;
  style?: React.CSSProperties;
};
export default function UIFlexbox({
  alignItems,
  children,
  className,
  direction,
  gap,
  grow,
  justifyContent,
  shrink,
  style,
}: Props) {
  return (
    <div
      className={"dFlex " + className ?? ""}
      style={{
        alignItems,
        flexDirection: direction,
        flexGrow: grow,
        flexShrink: shrink,
        gap,
        justifyContent,
        ...style,
      }}
    >
      {children}
    </div>
  );
}
